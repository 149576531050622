.side_bar_wrap{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    /* background-color: #F4F7FC; */
    width: 280px;
    height: 100%;
}
.sideLogo{
    background-image: url(../../assets/images/seafood7.svg);
    margin-left: 70px;
    width: 110px;
    height: 44px;
    margin-top: 70px;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
}
.selectUserByAdmin{
    height: 46px;
    border: 0px solid;
    margin-right: 30px;
    background-color: white;
    box-shadow: 0px 12px 29px #00277626;
    border-radius: 5px;
    color: #404855;
    outline: none;
    padding: 0px 10px;
}
ul{
    list-style: none;
    padding-left: 0px;
    margin-top: 94px;
}
ul li{
    padding: 20px 0px;
    position: relative;
    font-size: 14px;
    color: #58637A;
    cursor: pointer;
    padding-left: 70px;
    line-height: 100%;
    transition: all ease 0.5s;
    text-transform: capitalize;
}
.activeNav li, ul li:hover{
    color: #002776 !important;
    font-family: 'Poppins-Medium';
    background-color: #FFFFFF;
    transition: all ease 0.5s;
    background: rgb(233,235,255);
    background: linear-gradient(90deg, rgba(0,255,255,0.25) 0%, rgba(0,255,255,0.15) 42%, rgba(255,255,255,1) 100%);
}
.activeNav li::before, ul li:hover::before{
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 3px;
    background-color: #002776 !important;
    transition: all ease 0.5s;
}


a{
    text-decoration: none !important;
}
.header{
    height: 70px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    padding-right: 60px;
    align-items: flex-end;
    /* background-color: black; */
}

.pro_name{
    background-color: #FFFFFF;
    box-shadow: 0px 12px 29px #00277626;
    border-radius: 5px;
    padding: 15px;
    padding: 0px 40px 0px 70px;
    color: #404855;
    position: relative;
    height: 46px;
    line-height: 46px;
    cursor: pointer;
    background-image: url(../../assets/images/arrow-down-sign-to-navigate.png);
    background-size: 8px;
    background-position: 90% 19px;
    background-repeat: no-repeat;
}
.logout_pop{
    padding: 10px 25px;
    background-color: #FFFFFF;
    border-radius: 5px;
    position: absolute;
    right: 0;
    line-height: 100%;
    bottom: -45px;
    box-shadow: 0px 3px 10px 0px #00277626;
    z-index: 3;
    cursor: pointer;
    font-size: 14px;
}
.pro_ico{
    width: 30px;
    height: 30px;
    top: 8px;
    position: absolute;
    left: 20px;
    border-radius: 50%;
    /* background-image: url(../../assets/images/dummy-profile.png); */
    background-color: #002776;
    line-height: 30px;
    text-align: center;
    letter-spacing: 2px;
    font-size: 12px;
    font-weight: bold;
    color: white;
}
.notif_btn{
    padding: 15px;
    background-color: #FFFFFF;
    box-shadow: 0px 12px 29px #00277626;
    border-radius: 5px;
    height: 46px;
    width: 46px;
    margin-right: 30px;
    background-size: 20px;
    background-image: url(../../assets/images/bell_act.svg);
    background-position: center;
    background-repeat: no-repeat;
}
.nav_ico{
    position: absolute;
    width: 20px;
    height: 17px;
    left: 40px;
    background-image: url(../../assets/images/Sprite.png);
    top: calc(50% - 8px);
}
ul a:nth-child(1) li .nav_ico{
    background-position: 445px 102px;
}
ul a:nth-child(2) li .nav_ico{
    background-position: 368px 103px;
}
ul a:nth-child(3) li .nav_ico{
    background-position-y:55px !important;
    width: 28px;
    left: 32px;
    height: 14px;
    background-image: url(../../assets/images/inactive-icon-flight.svg);
}
ul a:nth-child(3) li:hover .nav_ico{
  background-image: url(../../assets/images/active-icon-flight.svg);
}
ul a:nth-child(4) li .nav_ico{
    background-position: 214px 103px;
}
ul a:nth-child(5) li .nav_ico{
    background-position: 135px 103px;
}
ul a:nth-child(6) li .nav_ico{
    background-position: 55px 103px;
}
ul a:nth-child(3).activeNav .nav_ico{
    background-image: url(../../assets/images/active-icon-flight.svg);
}

ul a:hover li .nav_ico, .activeNav li .nav_ico{
    background-position-y:55px !important;
}

.sfn-logo{
    background-image: url(../../assets/images/sfn-logo.png);
    margin-left: 70px;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 20px;
    bottom: 50px;
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
}
